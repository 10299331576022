@import '../../styles/partials/var';

.downloadMain{
    width: 100%;
    height: calc(100dvh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $bg-primary;
    color: $text-default;
    >header{
        width: 80%;
        height: 15%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        text-align: center;
        font-size: $h2-font-size;
    }
    >.gora{
        height: 25%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        >p{
            width: 80%;
            text-align: center;
        }
    }
    >img{
        height: 25%;
    }
    >.dol{
        height: 25%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        width: 100%;
        >p:first-of-type{
            margin-top: 1%;
        }
        >div{
            height: 50%;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }
    }
}