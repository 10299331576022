@import '../../styles/partials/var';

section#notFound {

    color: $text-default;

    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        max-width: 50%;
    }

    h1 {
        font-size: 5rem;
    }

    h2 {
        font-size: 2rem;
    }
}
