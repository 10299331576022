@import 'var';
@import 'mixins';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Poppins;
}

html {
    font-size: $font-size;
    background-color: $bg-primary;
}

body {
    line-height: 1.2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}

h1 {
    font-size: $h1-font-size;
}
h2 {
    font-size: $h2-font-size;
}
h3 {
    font-size: $h3-font-size;
}
h4 {
    font-size: $h4-font-size;
}
h5 {
    font-size: $h5-font-size;
}
h6 {
    font-size: $h6-font-size;
}

p {
    font-size: $p-font-size;
}


