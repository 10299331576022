$primary: #6e19ab;
$secondary-light: rgb(294, 150, 51);
$secondary-dark: rgb(173, 86, 0);
$primary-dark: rgb(77, 17, 119);
$secondary: rgb(248, 124, 0);
$primary-light: rgb(139, 71, 197);
$bg-secondary: #042c3d;
$bg-primary: #00151f;
$text-default: #ffffff;

$font-size: 1rem;

$h1-font-size: $font-size * 2.5;
$h2-font-size: $font-size * 2;
$h3-font-size: $font-size * 1.75;
$h4-font-size: $font-size * 1.5;
$h5-font-size: $font-size * 1.25;
$h6-font-size: $font-size * 1;

$p-font-size: $font-size * 1.1;
