@import '../../styles/partials/var';

@keyframes obrotFiszki {
    0% {transform: rotateY(0deg);}
    50% {transform: rotateY(90deg);}
    100% {transform: rotateY(0deg);}    
}

.mainOpenSet{
    width: 100%;
    padding: 2rem;
    min-height: calc(100dvh - 80px);
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    .background-image{
        position: fixed;
        filter: opacity(0.6) blur(5px) brightness(0.75);
        left: 20%;
        top: -20%;
        max-height: 150%;
        z-index: 4;
        @media screen and (max-width: 500px){
            max-height: 100%;
            left: -50%;
            top: 5%;
        }
    }
    
    
    header {
        font-size: $h1-font-size;
        font-family: Nasalization;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        height: 5dvh;
        z-index: 5;
        @media screen and (max-width: 500px){
            height: auto;
        }

    }
    div{
        z-index: 5;
        margin-top: -10px;
        height: 85dvh;
        width: 100%;
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 500px){
            flex-direction: column;
            min-height: 80dvh;
            height: auto;
            max-height: 150dvh;
            margin-top: 0;
        }
        
        align-items: center;
        justify-content: space-evenly;
        aside.fishkiList{
            width: 40%;
            max-height: 90%;
            min-height: 60%;
            height: 60%;
            background: linear-gradient(90deg, rgba(110,25,171,0.5) 0%, rgba(0,0,0,0.0) 50%, rgba(248,124,0,0.5) 100%);
            @media screen and (max-width: 500px){
                width: 90%;
                height: 30%;
                max-height: 60%;
                margin-top: 10px;
            }

            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            header{
                font-size: $h4-font-size;
                text-align: center;
                height: auto;
            }
            ul::-webkit-scrollbar {
                width: 0px;
            }
            ul{
                width: 100%;
                height: 80%;
                list-style: none;
                display: flex;
                flex-direction: column;

                @media screen and (max-width: 500px){ 
                    max-height: 350px;
                    overflow: scroll;
                }                

                align-items: center;
                overflow: auto;
                li{
                    padding-top: 10px;
                    padding-bottom: 10px;

                    width: 100%;
                    height: auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    
                    p{


                        height: 100%;
                        padding: 0 10px 0 10px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        width: 50%;
                    }
                    p:last-of-type{
                        text-align: right;
                        height: 100%;
                    }
                }
            }
        }
        .centerButtons {
            width: 30%;
            height: 60%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: column;

            @media screen and (max-width: 500px){ 
                width: 90%;
                height: 40%;
                margin-top: 30px;
                margin-bottom: 30px;
            }         
            
            button {
                width: 75%;
                font-size: 17px;
                padding-top: 8px;
                padding-bottom: 8px;
                @media screen and (max-width: 500px){ 
                    margin-bottom: 10px;
                }  
            }
            section {
                width: 75%;   
                display: flex;
                align-items: center;
                justify-content: space-between;
                >button {
                    width: 45%;
                }
            }
        }
        >aside.authorInfo{
            margin-top: -100px;
            width: 30%;
            height: 75%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-evenly;
            @media screen and (max-width: 500px){ 
                flex-direction: row;
                flex-wrap: wrap;
                width: 90%;
                margin-top: 0;
                >* {
                    margin-bottom: 10px;
                }
            }   
            span:hover{
                cursor: pointer;
            }
            >.photoAuthor{
                 
                width: 67%;
                height: auto;
                border-radius: 50%;
                @media screen and (max-width: 500px){ 
                    width: 50%;
                }  
            }
            >p{
                text-align: center;
            }

        }
        
    }
}

.versusWindow{
    width: 50%;
    height: 50%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    >div{
        width: 50%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        >svg{
            font-size: 150px;
        }
        transition: 200ms all;

    }
    >div:hover{
        transform: scale(1.3);
        cursor: pointer;
    }
}



