@import '../../styles/partials/var';

.joinMatchMain{
    background: $bg-primary;
    color: $text-default;
    width: 100dvw;
    height: calc(100dvh - 80px);
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    >header>p{
        font-size: $h1-font-size;
    }
    >section{
        display: flex;
        flex-direction: column;
        align-items: center;
        >div{
            width: 25dvw;
        }
        input{
            text-align: center;
            font-size: $h3-font-size;
        }
        label{
            text-align: center;
        }
        >button{
            margin-top: 40px;
        }
    }
}