@import '../../styles/partials/var';


.betaContainer{

    margin-top: 20px;

    border-radius: 10px;

    width: 100%;
    height: 275px;

    display: flex;

    align-items: baseline;
    justify-content: center;

    flex-direction: column;

        
    background-size: 50% 50%;
    background-repeat: no-repeat;

    position: relative;

    background: radial-gradient($primary 25%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0) 100%);

 

    >header{
        font-size: 60px;
        width: 100%;
        text-align: center;
        padding: 5px;
        background: -webkit-linear-gradient( #042C3D, #00151f);
        @media screen and (max-width: 400px) {

            background: $secondary;
            -webkit-background-clip: text;
        }
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        z-index: 5;
        font-family: Nasalization;
    }

    >p{
        color: $bg-primary;
        @media screen and (max-width: 400px) {
            color: $text-default;
        }
        z-index: 5;
        text-align: center;
        width: 100%;
    }


    >article{
        width: 100%;
        height: 100%;

        border-radius: 0 0 7.5px 7.5px ;
        display: flex;
        align-items: center;
        padding: 0 20px;
        overflow: scroll;
        >.setThumb{
            width: 200px;
            min-width: 200px;
            height: 200px;

            display: flex;
            flex-direction: column;
            align-items: center;
            >img{
                max-width: 80%;
                max-height: 160px;
                max-width: none;
            }
            >header{
                width: 100%;
                text-align: center;
                line-height: 40px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
        >.setThumb:not(:first-of-type){
            margin-left: 20px;
        }
        >.setThumb:hover{
            cursor: pointer;
        }
    }

}

.newSetsSectionContainer:has(>span){
    flex-direction: column;
    align-items: center;
}