// media query dla maksymalnych wartości //*TEGO UŻYWAMY
$breakpoints-max: (
    sm: 575.98px,
    md: 767.98px,
    lg: 991.98px,
    xl: 1199.98px,
    xxl: 1399.98px,
);
@mixin media($breakpoint) {
    @each $key, $size in $breakpoints-max {
        @if $breakpoint == $key {
            @media screen and (max-width: $size) {
                @content;
            }
        }
    }
}

// media query dla minimalnych wartości //!TEGO NIE UŻYWAMY ALE MUSI BYĆ
$breakpoints-min: (
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);
@mixin media-min($breakpoint) {
    @each $key, $size in $breakpoints-min {
        @if $breakpoint == $key {
            @media screen and (min-width: $size) {
                @content;
            }
        }
    }
}

// breakpointy do klasy container
$container-max-width: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
);
@mixin media-containers {
    @each $breakpoint, $size in $container-max-width {
        @include media-min($breakpoint) {
            .container {
                max-width: $size;
            }
        }
    }
}

// breakpointy dla klasy display
$displays: none, block, flex;
$breakpoints: sm, md, lg, xl, xxl;
@mixin displays {
    @each $breakpoint in $breakpoints {
        @include media-min($breakpoint) {
            @each $display in $displays {
                .#{$display}-#{$breakpoint} {
                    display: $display;
                }
            }
        }
    }
}
