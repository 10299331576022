@import '../../styles/partials/var';

.roadmapMain{
    width: 100%;
    height: calc(100dvh - 80px);
    display: flex;
    flex-direction: column;
    >header{
        width: 100%;
        font-size: $h1-font-size;
        margin-left: 30px;
        margin-top: 30px;
    }
    >section{
        font-size: 18px;
        width: 100%;
        margin-left: 30px;
        margin-top: 15px;
        display: block;
        >header{
            font-size: $h5-font-size;
        }
        ul{
            margin-left: 20px;
        }
    }
    >.warnings>ul>li::marker{
        color: rgb(255, 167, 38);
    }
    
    >.errors>ul>li::marker{
        color: red;
    }

    >footer{
        margin-left: 30px;
        margin-top: 40px;
        font-size: $h5-font-size;
    }
}

