@import '../../styles/partials/var';
@import '../../styles/partials/mixins';

@keyframes nastepnePisanie{
    0% {transform: translateX(0)};
    50% {transform: translateX(-80vw); opacity: 0;};
    51% {transform: translateX(180vw)};
    100% {transform: translateX(0); opacity: 1};
}

.mainMatch{
    background: $bg-primary;
    color: $text-default;
    width: 100%;
    padding: 2rem;
    min-height: calc(100dvh - 80px);
    height: calc(100dvh - 80px);
    display: flex;
    align-items: center;
    flex-direction: column;
    header {
        font-size: $h2-font-size;
        width: 100%;
        text-align: center;
        height: 5dvh;
        display: flex;
        justify-content: center;
        @media screen and (max-width: 500px){
            height: 15dvh;
        }
    }

    >div{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        height: 100%;
        >aside{
            width: 40%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            >img{
                max-width: 75%;
                max-height: 40%;
                object-fit: cover;
                aspect-ratio: 1;
                border-radius: 100dvw;
            }
            >p{
                font-size: $h3-font-size;
            }
        }
    }

    article{
        height: 70dvh;
        width: 60%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 50px;
        flex-direction: column;



        header{
            font-size: $h1-font-size;
        }

        >div{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 50%;
        }

        div>div{
            font-size: $h2-font-size;
            text-align: center;
            
        }

        div>input{
            text-align: center;
        }

        >div>div{
            width: 100%;
            font-size: $h2-font-size;
            @include media(sm){
                display: flex;
                justify-content: center;
                align-items: center;
                width: 75%;
            }
        }

        .horizontalContainer {
            button{
                position: fixed;
                @include media(sm){
                    right: 0;
                }
            }

        }

        span{
            display: inline-block;
            height: 15%;
            padding-top: 20px;
            font-size: $h5-font-size;
        }
    }

    footer{
        font-size: $h4-font-size;
    }

    .lettersContainer{
        margin-top: 30px;
        height: max-content;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: $h4-font-size;
        @include media(sm){
            flex-wrap: wrap-reverse;
            justify-content: space-evenly;
            button{
                margin-bottom: 10px;
            }
        }
    }

    .finishedSection{
        position: absolute;
        width: 100dvw;
        height: 100dvh;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        background-color: rgba(0, 0, 0, 0.8);
        header{
            font-size: $h1-font-size;
        }
        >div{
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            font-size: $h1-font-size;
            text-align: center;
        }
        button{
            font-size: $h5-font-size;
        }
        img{
            height: 400px;
            width: 400px;
        }
    }

}

.finishedSection{
    position: absolute;
    width: 100dvw;
    height: 100dvh;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: rgba(0, 0, 0, 0.8);
    header{
        font-size: $h1-font-size;
    }
    >div{
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-size: $h1-font-size;
        text-align: center;
    }
    button{
        font-size: $h5-font-size;
    }
    img{
        height: 400px;
        width: 400px;
    }
}
