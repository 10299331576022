@import '../../styles/partials/var';
@import '../../styles/partials/mixins';

main#loginOrReg {
    background: $bg-primary;
    color: $text-default;
    padding: 2rem;
    height: calc(100dvh - 80px);
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;

        aside {
            display: flex;
            align-items: center;
            gap: 1rem;

            img {
                width: 5rem;
            }
        }

        .card {
            border-radius: 20px;
            margin: 0 auto;
            width: 50%;
            height: 100%;
            padding: 3rem 5rem;

            background: linear-gradient(to bottom, $bg-primary, $bg-secondary);

            @include media(xl) {
                padding: 2rem 3.5rem;
                width: 65%;
            }

            @include media(lg) {
                padding: 2rem;
                width: 75%;
            }

            @include media(md) {
                width: 100%;
            }
        }
    }
}

form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    opacity: 0;
    animation: fade 0.2s forwards ease-in-out;

    >h2 {
        font-family: Nasalization;
    }

    fieldset {
        width: 100%;
        border: none;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 2rem;

        img {
            width: 5rem;
        }

        .button-group {
            display: flex;
            align-items: stretch;
            justify-content: center;
            gap: 1rem;

            > div,
            button {
                width: 50%;
            }

            margin-bottom: 20px;
            //background-color: #c50404
        }
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
