@import 'mixins';

.container,
.container-full {
    width: 100%;
    margin-inline: auto;
    padding-inline: 0.75rem;
}

@include media-containers;
@include displays;

.modal{
    display: flex; 
    justify-content: center; 
    align-items: center;

    .modalContent{
        width: 405px; 
 
        background-color: rgb(32, 32, 32); 
        border-radius: 10px;
        border: 1px solid rgb(32, 32, 32);

        color: rgb(220, 220, 220);
        padding: 30px;

        h1{
            margin-bottom: 10px;
        }

        button{
            margin-top: 20px;
            float: right;
        }
    }
}
