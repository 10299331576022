@import '../../styles/partials/var';
@import '../../styles/partials/mixins';

@keyframes nastepnePisanie{
    0% {transform: translateX(0)};
    50% {transform: translateX(-80vw); opacity: 0;};
    51% {transform: translateX(180vw)};
    100% {transform: translateX(0); opacity: 1};
}

.mainWriteSet{

    >*{
        z-index: 5;
    }

    .background-image{
        position: fixed;
        filter: opacity(0.6) blur(5px) brightness(0.75);
        left: 20%;
        top: -20%;
        max-height: 150%;
        z-index: 4;
        @media screen and (max-width: 500px){
            max-height: 100%;
            left: -50%;
            top: 5%;
        }
    }

    width: 100%;
    padding: 2rem;
    min-height: calc(100dvh - 80px);
    display: flex;
    align-items: center;
    flex-direction: column;
    >header{
        font-family: Nasalization;
    }
    header {
        z-index: 5;
        font-size: $h2-font-size;
        width: 100%;
        text-align: center;
        height: 5dvh;
        display: flex;
        justify-content: center;
        @media screen and (max-width: 500px){
            height: 15dvh;
        }
    }

    article{
        z-index: 5;
        height: 70dvh;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 50px;
        flex-direction: column;



        header{
            font-size: $h1-font-size;
        }

        >div{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 50%;
        }

        div>div{
            font-size: $h2-font-size;
            text-align: center;
            
        }

        div>input{
            text-align: center;
        }

        >div>div{
            width: 100%;
            font-size: $h2-font-size;
            @include media(sm){
                display: flex;
                justify-content: center;
                align-items: center;
                width: 75%;
            }
        }

        .horizontalContainer {
            button{
                position: fixed;
                @include media(sm){
                    right: 0;
                }
            }

        }

        span{
            display: inline-block;
            height: 15%;
            padding-top: 20px;
            font-size: $h5-font-size;
        }
    }

    footer{
        font-size: $h4-font-size;
    }

    .lettersContainer{
        margin-top: 30px;
        height: max-content;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: $h4-font-size;
        @include media(sm){
            flex-wrap: wrap-reverse;
            justify-content: space-evenly;
            button{
                margin-bottom: 10px;
            }
        }
    }

    .finishedSection{
        position: absolute;
        width: 100dvw;
        height: 100dvh;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        background-color: rgba(0, 0, 0, 0.8);
        header{
            font-size: $h1-font-size;
        }
        >div{
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            font-size: $h1-font-size;
            text-align: center;
            @media screen and (max-width: 500px){
                flex-direction: column;
                >img{
                    width: 60%;
                    height: auto;
                    aspect-ratio: 1;
                }
            }
        }
        button{
            font-size: $h5-font-size;
            @media screen and (max-width: 500px){
                margin-bottom: 15px;
            }
        }
        img{
            height: 400px;
            width: 400px;
        }
    }

}




