@import '../../styles/partials/var';

@keyframes obrotFiszki {
    0% {transform: rotateY(0deg);}
    50% {transform: rotateY(90deg);}
    100% {transform: rotateY(0deg);}    
}

.mainNewSet{
    background: $bg-primary;
    color: $text-default;
    width: 100%;
    padding: 2rem;
    min-height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    header {
        font-size: $h1-font-size;
        width: 40%;
        div{
            height: auto;
            width: 100%;
            font-size: $h2-font-size;
        }
    }
    div{
        height: 80dvh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        // flex-direction: row;
        aside{
            width: 45%;
            max-height: 50%;
            min-height: 50%;
            height: 50%;
            //height: 80%;
            border: 3px solid rgb(220, 220, 220);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            header{
                font-size: $h4-font-size;
                text-align: center;
            }
            ul{
                width: 100%;
                height: 80%;
                list-style: none;
                display: flex;
                flex-direction: column;
                border-top: 2px solid rgb(220, 220, 220);
                align-items: center;
                overflow: auto;
                li{
                    border-bottom: 2px solid rgb(220, 220, 220);
                    width: 100%;
                    height: auto;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    
                    span{
                        width: 40%;
                        padding: 0 10px 0 10px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    span:last-of-type{
                        color: gray;
                    }
                }
            }
        }
        .midtemp
        {
            width: 41%;
            height: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            padding: 10px;
        }
        .mid{
            height: auto;
            width: 9%;
            height: 100%;
             display: flex;
            flex-direction: column;
        }
        article{
            margin: 3% 0 3% 0;
            width: 60%;
            height: 80%;
            border: 3px solid rgb(220, 220, 220);
            //box-shadow: 0px 0px 15px black;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            header{
                height: 10%;
                width: 100%;
                font-size: $h4-font-size;
                text-align: center;
            }
            div{
                height: 80%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                div{
                    width: 80%;
                    height: auto;
                }
            }
            animation: obrotFiszki 0.5s linear;
        }
        
        .buttonsBox{
            width: 30%;
            height: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            div{
                height: auto;
                width: fit-content;
            }
        }
    }
}

.lettersContainer{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: $h4-font-size;
}


