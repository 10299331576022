@import '../../styles/partials/var';

.newSetsSectionContainer {
    margin-top: 20px;

    border-radius: 10px;

    width: 100%;
    height: 275px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    > header {
        font-size: $h4-font-size;
        width: 100%;
        padding: 5px;
    }
    > span {
        font-size: $h4-font-size;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    > article {
        width: 100%;
        height: 100%;

        border-radius: 0 0 7.5px 7.5px;
        display: flex;
        align-items: center;
        padding: 0 20px;

        overflow-y: hidden;

        > .setsHeader {
            width: 200px;
            min-width: 200px;
            height: 200px;
            transition: all 200ms;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-right: 2px solid white;
            >header{
                color: $primary-light;

                font-size: 25px;
                transition: all 200ms;
                text-align: right;
                padding-right: 10px;
                >span{
                    color: $secondary-light;
                    font-size: 40px;
                    font-family: Nasalization;
                }
            }
        }

        >.setsHeader:hover{
            cursor:default;
            >header{
                font-size: 40px;
            }
        }

        >.setThumb:nth-of-type(odd) {
            >.imgBG {
                background: radial-gradient($primary-dark 25%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0) 100%);
            }
            
        }

        >.setThumb:nth-of-type(even) {
            >.imgBG {
            background: radial-gradient($secondary-dark 25%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0) 100%);
            }
        }

        > .setThumb {
            width: 200px;
            min-width: 200px;
            height: 200px;
            transition: all 200ms;
            display: flex;
            flex-direction: column;
            align-items: center;

            >.imgBG {
                width: 200px;
                height: 200px;
                
                left: 0;
                top: 0;
                border-radius: 30px;
                position:absolute;
                display: none;
                transition: 200ms all;
            }
            > img {
                max-width: 80%;
                max-height: 160px;
                max-width: none;
                transition: all 200ms;
                z-index: 5;
            }
            > header {
                width: 100%;
                text-align: center;
                line-height: 40px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                z-index: 5;
            }
        }
        > .setThumb:not(:first-of-type) {
            margin-left: 20px;
        }
        > .setThumb:hover {
            cursor: pointer;
            transform: scale(1.1);

            >.imgBG{
                display: block;
            }
        }
    }
}

.newSetsSectionContainer:has(> span) {
    flex-direction: column;
    align-items: center;
}
