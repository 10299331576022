@import '../../styles/partials/var';
@import '../../styles/partials/mixins';


.writeTestMain{
    width: 100%;
    padding: 2rem;
    min-height: calc(100dvh - 80px);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 0;
    >header {
        width: 100%;
        text-align: center;
        height: 7dvh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 50px;
        @media screen and (max-width: 500px){
            height: 15dvh;
        }
        >h1{
            font-size: $h2-font-size;
        }
        >p{
            margin-top: 10px;
            font-size: $h5-font-size;
        }
    }
    
    >.prawdaFalsz, >.abcd, >.writing{
        text-align: center;
        width: 50%;
        min-width: 300px;
        min-height: 200px;
        border-top: 2px solid white;
        margin-top: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    .prawdaFalsz{
        >header{
            width: 100%;
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            
            font-size: $h5-font-size;
        }
        >div{
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            >button{
                @media screen and (max-width: 500px){
                    width: 45%;
                }
            }
        }
    }
    .abcd{
        >header{
            width: 100%;
            height: 25%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $h5-font-size;
            margin-bottom: 20px;
        }
        >div{
            width: 100%;
            height: 75%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            >div{
                width: 100%;
                height: 50%;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                >button{
                    width: 30%;
                    @media screen and (max-width: 500px){
                        width: 45%;
                    }
                    margin-bottom: 10px;
                }
            }
        }
    }
    .writing{
        >header{
            width: 100%;
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $h5-font-size;
        }
        >div{
            width: 75%;
        }
        >div{
            font-size: $h5-font-size;
        }
    }
    h3{
        width: 75%;
        min-width: 300px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    >footer{
        height: calc(10dvh + 1rem);
        width: 100dvw;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-evenly;
        padding-bottom: 1rem;
    }
    >.resultMain{
        width: 100%;
        height: 60dvh;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: row;
        >img{
            width: 30%;
        }
        >p{
            font-size: $h4-font-size;
            text-align: center;
            white-space:break-spaces;
        }
        
    }
    >.resultsList{
        width: 100%;
        height: auto;
        min-height: 50dvh;
        margin-bottom: 50px;
        >header {
            width: 100%;
            text-align: center;
            font-size: $h5-font-size;
            margin-bottom: 20px;
        }
        >table{
            width: 100%;
            font-size: 18px;
            border-collapse: collapse;
            border: 2px solid white;
            >thead{
                font-size: 20px;
            }
            td{
                padding: 10px;
            }
        }
    }
}
