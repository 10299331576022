@import '../../styles/partials/var';

.verifyMain{
    width: 100dvw;
    height: calc(100dvh - 80px);
    display: flex;
    align-items: center;
    flex-direction: column;
    >header{
        width: 100%;
        height: 30%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        text-align: center;
        >h1{
            font-size: 48px;
        }
    }
    >p{
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    >section{
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        >div{
            width: 30%;
            min-width: 300px;
        }
    }
}