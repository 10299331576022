@import '../../styles/partials/var';

.setEditorMain { 
    width: 100vw;
    height: calc(100vh - 80px);
    color: $text-default;
    .background-image{
        position: fixed;
        filter: opacity(0.6) blur(5px) brightness(0.75);
        left: 20%;
        top: -20%;
        max-height: 150%;
        z-index: 4;
        @media screen and (max-width: 500px){
            max-height: 100%;
            left: -50%;
            top: 5%;
        }
    }
    *:not(.background-image) {
        z-index: 5;
    }
    >header {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        >h1 {
            color: $text-default;
            font-family: Nasalization;
            text-align: center;
        }
        
    }
    > article { 
        width: 100%;
        height: calc(100% - 50px); 
        display: flex;
        flex-direction: row;
        align-items: center;
        >aside.setOptions {
            width: 33%;
            display: flex;
            align-items: center;
            flex-direction: column;
            >div {
                width: 80%;
                font-size: $h2-font-size;
                > div {
                    font-size: $h2-font-size;
                }
            }
            > h4, >p{
                width: 80%;
                text-align: left;
                color: $text-default;
            }
            >h4:not(:first-of-type) {
                margin-top: 20px;
            }
            >h4 {
                color: $primary;
            }
            >p { 
                margin-top: 10px;
            }
            >section {
                margin-top: 10px;
                width: 80%;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                flex-direction: row;
                > * {
                    width: 35%;
                }
            }
        }
        >main {
            width: 33%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: column;
            >section.fiszka {
                width: 80%;
                aspect-ratio: 1;
                border-radius: 10px;
                >header {
                    text-align: center;
                    font-size: $h4-font-size;
                    height: 15%;
                }
                >div {
                    width: 100%;
                    height: 70%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    >div {
                        width: 80%;
                        >div {
                            width: 100%;
                            
                        }
                        * {
                            border: 0px;
                            text-align: center;
                            font-size: $h4-font-size;
                        }
                    }
                }
            }
            >section.lettersContainer {
                height: 20%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
        >aside.setList {
            width: 33%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: column;
            
            >section {
                width: 90%;
                border-radius: 10px;
                min-height: 60%;
                max-height: 80%;
                //background: linear-gradient(90deg, rgba(110,25,171,0.5) 0%, rgba(0,0,0,0.0) 50%, rgba(248,124,0,0.5) 100%);
                background: linear-gradient(to bottom, $bg-secondary 0%, transparent 100%);
                >header {
                    text-align: center;
                    font-size: $h3-font-size;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
                >ul {
                    list-style: none;
                    width: 100%;
                    max-width: 100%;
                    height: auto;
                    overflow-y: scroll;
                    max-height: 400px;
                    >li {
                        width: 100%;
                        
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        justify-content: space-evenly;
                        >p { 
                            width: 35%;
                            text-overflow:ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            height: 30px;
                        }
                        >p:nth-of-type(even) {
                            text-align: right;
                            
                        }
                        >button { 

                        }
                    }
                }
                >ul::-webkit-scrollbar {
                    width: 0px;
                }
            }
        }
    }
    .helpCard {
        width: 80%;
        height: 80%;
        background-color: $bg-primary;
        padding: 20px;
        >header {
            height: 15%;
            font-size: $h1-font-size;
            color: $primary-light;
        }
        >p {
            margin-top: 20px;
            font-size: 1.25rem;
        }
        >button {
            margin-top: 40px;
        }
    }
}