@import '../../styles/partials/var';
@import '../../styles/partials/mixins';

header#header {
    z-index: 100;
    height: 80px;
    padding-inline: 2rem;
    position: relative;
    background-color: transparent;


    @include media(lg) {
        height: 60px;
    }

    .container-full {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    a.navbar-brand {
        height: 100%;
        color: white;
        text-decoration: none;
        display: flex;
        align-items: center;
        position: relative;
        gap: 1.5rem;
        padding: 1rem 0;
        font-size: 2.5rem;


        >p{
            color: white;
            text-decoration: none;
            gap: 1.5rem;
            padding: 1rem 0;
            font-size: 2.5rem;
            font-family: Nasalization;
            font-weight: lighter;
            padding: 0;
            padding-top: 8px;
        }

        @include media(lg) {
            z-index: 5;
            font-size: 1.75rem;
            padding: 0.5rem 0;
        }

        img {
            z-index: 5;
            height: 100%;
        }

        >.navbar-brand-bg{
            z-index: 4;
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }



    .navbar-brand:hover{
        cursor: pointer;
        >.navbar-brand-bg {
            opacity: 1;
        }
    }

    span.toggler {
        display: none;

        @include media(lg) {
            display: block;
            svg {
                font-size: 2rem;
            }
        }
    }

    nav.navbar {
        transition: right 0.2s ease-out;
        @include media(lg) {
            padding: 2rem;
            z-index: 2;
            position: fixed;
            right: -350px;
            top: 60px;
            width: 350px;
            height: calc(100dvh - 60px);

            &.showed {
                right: 0;
                background: linear-gradient(to bottom, $bg-primary 0%, $bg-secondary 30%, $bg-secondary 70%, $bg-primary 100%);
            }
        }

        @media screen and (max-width: 350px) {
            width: 100dvw;
            right: -100dvw;
        }

        ul.links {
            display: flex;
            align-items: center;
            gap: 1.5rem;
            font-size: 1.25rem;

            list-style: none;
            padding: 0;

            @include media(lg) {
                flex-direction: column;
            }

            li {
                a {
                    color: white;
                    text-decoration: none;
                    position: relative;

                    &.underline::after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 105%;
                        transform: translateX(-50%);
                        width: 0;
                        height: 2px;
                        background-color: $primary;
                        transition: width 0.2s ease-in-out;
                    }

                    &.underline:hover::after {
                        width: 100%;
                    }

                    img {
                        width: 55px;
                        aspect-ratio: 1;
                        padding: 0;
                        border-radius: 50%;
                        border: 2px solid $primary;
                        box-sizing: border-box;
                        transition: 200ms all;
                    }

                    img:hover {
                        filter: opacity(0.65);
                    }
                }
            }
        }
    }
}
