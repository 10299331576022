@import '../../styles/partials/var';

@keyframes nastepnaFiszka{
    0% {transform: translateX(0)};
    50% {transform: translateX(-80vw); opacity: 0;};
    51% {transform: translateX(180vw)};
    100% {transform: translateX(0); opacity: 1};
}

.mainBrowseSet{
    background-color: $bg-primary;
    color: $text-default;
    width: 100%;
    padding: 2rem;
    min-height: calc(100dvh - 80px) ;
    display: flex;
    align-items: center;
    flex-direction: column;
    .background-image{
        position: fixed;
        filter: opacity(0.6) blur(5px) brightness(0.75);
        left: 20%;
        top: -20%;
        max-height: 150%;
        z-index: 4;
        @media screen and (max-width: 500px){
            max-height: 100%;
            left: -50%;
            top: 5%;
        }
    }
    >header{
        font-family: Nasalization;
    }
    header {
        font-size: $h2-font-size;
        
        width: 100%;
        text-align: center;
        height: 5dvh;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
    }
    article{
        z-index: 5;
        animation-iteration-count: infinite;
        margin-top: 20px;
        width: 100%;
        height: 75dvh;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: row;
        aside{
            width: 15%;
            height: 100%;
            @media screen and (max-width: 500px){
                width: 10%;
            }
            display: flex;
            justify-content: center;
            align-items: center;
        }
        aside>div{
            scale: 1.75;
        }
        aside>div:hover{
            cursor: pointer;
        }
        section{
            height: 100%;
            width: 50%;
            
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-direction: column;
            @media screen and (max-width: 500px){
                width: 80%;
            }
            article{
                margin: 3% 0 3% 0;
                width: 70%;
                height: auto;
                border: 3px solid rgb(220, 220, 220);
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                aspect-ratio: 1;
                header{
                    height: 10%;
                    width: 100%;
                    font-size: $h4-font-size;
                    color: $text-default;
                    text-align: center;
                    @media screen and (max-width: 500px){
                        font-size: $h6-font-size;
                    }
                }
                div{
                    margin-top: -10%;
                    height: 90%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: $h3-font-size;
                    text-align: center;
                    @media screen and (max-width: 500px){
                        font-size: $h5-font-size;
                    }
                }
                //animation: obrotFiszki 0.5s linear;
                //animation: nastepnaFiszka 0.5s linear
            }
            article:hover{
                cursor: pointer;
            }
        }
        footer{
            font-size: $h4-font-size;
        }
    }
}