@import '../../styles/partials/var';

.createMatchMain{
    width: 100dvw;
    height: calc(100dvh - 80px);

    >header{
        width: 100%;
        font-size: $h2-font-size;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
    }
    >div{
        height: calc(100% - 60px);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        >aside{
            width: 30%;
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            >img{
                height: 50%;
                width: auto;
            }
        }
        >section{
            width: 33%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            >img{
                width: 75%;
                aspect-ratio: 1;
                border-radius: 100dvw;
                object-fit: cover;
            }
        }
        >p{
            width: 4%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $h2-font-size;
        }
    }
    .matchCode{
        font-size: $h3-font-size;
    }
}

