@import '../../styles/partials/var';

.homeMain{
    background-color: $bg-primary;
    color: $text-default;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
    height: 100%;
    .appBar{
        width: 100%;
        height: 10%;
        max-height: 150px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;


        aside{
            button:not(:last-of-type){
                margin-right: 20px;
            }
        }

        

    }

    .homeContainer{
        min-height: 90%;
        width: 100%;
        display: flex;

        align-items: center;
        flex-direction: column;
    }
    
}

.userNameAppBar{
    font-size: $h3-font-size;
}

